
const App = () => {
  return (
    <>
      <div className="app">
        <h1>Frens GO <img src="/hand.png" alt="hand" /></h1>
        <img src="/logo.png" alt="logo" className="app-logo" />
        <img src="/progres.png" alt="progres" />
        <h3>Frens GO — only forward</h3>
        <div className="app-btns">
          <a href="https://dexscreener.com/ton/eqcjfcvodux44s1e9cyzeor4k0fih610rszv49vbkbyzcv9r">Отслеживать график</a>
          <a href="https://app.ston.fi/swap?chartVisible=false&chartInterval=1w&ft=TON&tt=EQBT_npjSoNBzieq62hybdpIpjvEKcMCkKpjS_wYlTf6R-jC">Купить токен</a>
          <a href="https://t.me/frenstelegram">Телеграм чат</a>
        </div>
      </div>
    </>
  )
}

export default App